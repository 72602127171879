import humanoid from '../../assets/images/humanoid.webp'
import {motion} from 'framer-motion'

export default function Homepage() {
    return (
        <motion.div className="homepage">
            <motion.div 
                exit = {{left : -1000, scale : 0}}
                initial = {{left : -1000, scale : 0}}
                animate = {{left : 50, scale : 1, transition : {delay : 0.5, duration : 2}}}
                className='homepage--image'
            >
                <img src={humanoid} alt="Humanoid" />
            </motion.div>
            <motion.div 
                exit = {{right : -1500}}
                initial = {{right : -1500}}
                animate = {{right : 0, transition : {delay : 0.5, duration : 2}}}
                className='homepage--introduction'
            >
                <h1>Connectez-vous au futur, à l'ombre des palmiers.</h1>
                <h2>Plongez dans l'ère digitale avec la fraîcheur du tropique à portée de main</h2>
                <button className='homepage--button'>Commencer</button>
            </motion.div>    
        </motion.div>
    )
}