
import Homepage from "../components/home/Homepage"
import Applications from "../components/apps/Applications"


export type NavElementConfig = {
    id : string, 
    name : string, 
    path : string, 
    component? : React.ReactNode
}

export const nav_config:NavElementConfig[] = [
    {
        id : "NAV-01", 
        name : "Accueil", 
        path : "/",
        component : <Homepage />
    }, 
    {
        id : "NAV-02", 
        name : "Applications", 
        path : "/applications", 
        component : <Applications />
    }
]