import React from 'react' ; 
import {ReactComponent as PulsifyIcon} from '../assets/svg/pulsify_logo.svg'

export type AppElementConfig = {
    id : string, 
    name : string, 
    url : string, 
    slogan : string, 
    description : string, 
    svg? : React.ReactNode, 
    image? : any
}


export const apps_config:AppElementConfig[] = [
    {
        id : "APP-01",
        name : "Pulsify",
        url : "https://pulsify.tropicode.re",
        slogan : "Quand le temps pulse au rythme de vos besoins",
        description : "Découvrez Pulsify, guidé par l'Horloger, votre allié pour maîtriser le temps. Choisissez votre manière de capturer le temps.", 
        svg : <PulsifyIcon />
    }, 


]