import React from 'react' ; 
import {ReactComponent as LogoSVG} from '../../assets/svg/logo.svg'
import { nav_config, NavElementConfig } from '../../config/nav.config';
import {motion} from 'framer-motion'; 
import { Link } from 'react-router-dom';

export default function Navigation() {
    return (
        <motion.nav 
            exit = {{top : -100}}
            initial = {{top : -100}}
            animate = {{top : 0, transition : { delay : 0, duration : 0.5}}}
            className="nav"
        >
            <div className="nav--logo">
                <LogoSVG />
            </div>
            <ul className="nav__list">
                {
                    nav_config.map((navEl:NavElementConfig) => (
                        <li key = {navEl.id} className="nav__list--element">
                            <Link to={navEl.path}>{navEl.name}</Link>
                        </li>
                    ))
                }
            </ul>
            <a href = {`https://bridge.tropicode.re`} className='nav--login'>
                Connexion
            </a>
        </motion.nav>
    )
}