import React from 'react'
import {ReactComponent as AppIcon} from '../../assets/svg/app_icon.svg' ; 
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/grid';
import 'swiper/css/autoplay';
import 'swiper/css/mousewheel';

import { Pagination, Grid, Autoplay, Mousewheel } from 'swiper/modules';
import { AppElementConfig, apps_config } from '../../config/apps.config';

export default function Applications() {
    return (
        <div className="applications">
            <div className='applications--introduction'>
                <AppIcon />
                <h1>Notre store</h1>
            </div>

            <div className='applications__list'>
                <Swiper 
                    pagination={{clickable : true}} 
                    modules={[Grid, Pagination, Autoplay, Mousewheel]} 
                    autoplay= {{
                        delay : 3000, 
                        disableOnInteraction : false
                    }}
                    className="mySwiper"
                    slidesPerView={2}
                    grid={{rows : 2}}
                    mousewheel={true}
                    spaceBetween={0}
                >
                    {
                        apps_config.map((app:AppElementConfig) => (
                            <SwiperSlide key={app.id}>
                                <div className='applications__list--element'>
                                    <a href={app.url} target="_blank" rel="noreferrer">
                                        <div className='logo'>{app.svg}</div>
                                        <h2>{app.name}</h2>
                                        <p id = "baseline">{app.slogan}</p>
                                        <p id = "description">{app.description}</p>
                                    </a>
                                </div>
                            </SwiperSlide>
                        
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}