import {BrowserRouter, Routes, Route} from 'react-router-dom' ; 
import { nav_config } from '../config/nav.config';
import Navigation from './general/Navigation';

export default function Router() {
    return (
        <BrowserRouter>
            <Navigation />
            <div className='app--container'>
                <Routes>
                    {
                        nav_config.map((navEl) => (
                            <Route key = {navEl.id} path={navEl.path} element={navEl.component} />
                        ))
                    }
                </Routes>
            </div>
        </BrowserRouter>
    )
}